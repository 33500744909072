import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";

import { FormGroup } from "./components/FormGroup";
import { HomeInfoContext } from "./HomeInfoContext";

export const HomeInfoFormSidebar = ({ sidebarName }) => {
  const { homeInfo, sidebarDraft, setSidebarDraft } = useContext(
    HomeInfoContext
  );
  const { setFieldValue } = useFormikContext();
  const [index, setIndex] = useState();
  const [fieldName, setFieldName] = useState(null);

  useEffect(() => {
    const valueIndex = homeInfo?.home_info_internal_notes_attributes.findIndex(
      (note) => note.name === sidebarName
    );
    if (valueIndex !== -1) {
      setIndex(valueIndex);
    } else {
      setIndex(homeInfo?.home_info_internal_notes_attributes.length);
    }
  }, [homeInfo, index, setFieldValue, sidebarName]);

  /** A workaround to avoid uncontrolled/controlled component change error */
  useEffect(() => {
    setFieldName(`home_info_internal_notes_attributes[${index}]`);
    setFieldValue(
      `home_info_internal_notes_attributes[${index}].name`,
      sidebarName
    );
  }, [index, setFieldValue, sidebarName]);

  useEffect(() => {
    if (sidebarDraft?.notes) {
      setFieldValue(
        `home_info_internal_notes_attributes[${index}].notes`,
        sidebarDraft.notes
      );
    }
  }, [index, setFieldValue, sidebarDraft?.notes]);

  const afterOnChange = (name) => (event) => {
    setSidebarDraft({
      name,
      notes: event.target.value
    });
  };

  return (
    <>
      <h4 className="section-name">Internal Notes – {sidebarName}</h4>
      <p className="text-secondary">Not visible to hosts or guests</p>
      {fieldName && <FormGroup name={`${fieldName}.name`} type="hidden" />}
      <FormGroup
        name={`home_info_internal_notes_attributes[${index}].notes`}
        component="textarea"
        rows={15}
        afterOnChange={afterOnChange(sidebarName)}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-submit" type="submit">
          Save
        </button>
      </div>
    </>
  );
};
