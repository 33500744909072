import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { Modal } from "../../../../common/Modal";
import { showErrorToast } from "../../../../common/modals";
import { deleteFile } from "../data/files/thunks";

export const DeleteFileModalContainer = ({ file, onClose }) => {
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = useCallback(async () => {
    setPending(true);

    try {
      await dispatch(deleteFile(file.id));
      // The modal will be automatically closed therefore at this point
      // the parent component will be unmounted.
    } catch (error) {
      showErrorToast("Unable to delete the file!");
      setPending(false);
    }
  }, [dispatch, file]);

  return (
    <Modal onClose={onClose}>
      <Modal.Header>
        Are you sure you want to delete {file.file_name}?
      </Modal.Header>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light"
          onClick={onClose}
          disabled={pending}
        >
          Cancel
        </button>

        <button
          type="button"
          className="btn btn-danger"
          onClick={handleConfirm}
          disabled={pending}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};
