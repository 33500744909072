import { connect } from "formik";
import get from "lodash/get";
import React from "react";

import { FormGroup } from "../../../../../common/forms/FormGroup";
import { ClearLocationButton } from "./ClearLocationButton";
import { PickLocationButton } from "./PickLocationButton";

const getAddress = (values) => {
  const {
    property_attributes: { street, city, postcode, country }
  } = values;

  return { street, city, postcode, country };
};

export const LocationFields = connect(
  ({ name, formik: { values, setFieldValue } }) => {
    const setLocation = ({ lat, lng }) => {
      setFieldValue(name.lat, lat);
      setFieldValue(name.lng, lng);
    };

    const handleClearLocation = () => setLocation({ lat: "", lng: "" });

    return (
      <div className="form-row" data-testid="location-fields">
        <div className="col-md-3 align-self-end">
          <div className="form-group">
            <PickLocationButton
              location={{
                lat: get(values, name.lat),
                lng: get(values, name.lng)
              }}
              address={getAddress(values)}
              onConfirm={setLocation}
            />
          </div>
        </div>

        <div className="col-md-3">
          <FormGroup name={name.lat} label="Latitude" placeholder="" readOnly />
        </div>

        <div className="col-md-3">
          <FormGroup
            name={name.lng}
            label="Longitude"
            placeholder=""
            readOnly
          />
        </div>

        <div className="col-md-1 align-self-end">
          <ClearLocationButton onClick={handleClearLocation} />
        </div>
      </div>
    );
  }
);
