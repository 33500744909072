import { apiClient } from "../../../common/apiClient";

export const fetchHomeInfo = (property_id) =>
  apiClient
    .get(`/api/properties/${property_id}/home_info`)
    .then((response) => response.data);

export const updateHomeInfo = (property_id, values) =>
  apiClient
    .patch(`/api/properties/${property_id}/home_info`, { home_info: values })
    .then((response) => response.data);

export const updateHomeInfoInternalNotes = (property_id, values) =>
  apiClient
    .patch(`/api/properties/${property_id}/home_info/update_internal_notes`, {
      home_info: values
    })
    .then((response) => response.data);

export const fetchAmenityTypes = (category) => {
  return apiClient
    .get("/api/home_info_amenity_types", { params: { category } })
    .then((response) => response.data);
};

export const fetchPropertyFiles = (property_id) => {
  return apiClient
    .get(`/api/properties/${property_id}/files`)
    .then((response) => response.data);
};

export const fetchAirbnbListingData = (listing_id) => {
  return apiClient
    .get(`/api/${listing_id}/airbnb_listing_data`, {
      validateStatus(status) {
        return status < 500;
      }
    })
    .then((response) => response.data);
};
