import { fireEvent, waitFor } from "@testing-library/react";
import React from "react";

import { updateFileResponse } from "../data/files/actions";
import { renderWithMockStore } from "../data/testHelpers";
import { EditFileModalContainer } from "./EditFileModalContainer";

describe("<EditFileModalContainer />", () => {
  const initialState = {
    property: { id: 123 }
  };

  const file = {
    id: 1234,
    file_name: "image01.jpg",
    folder_name: "Lockbox",
    uploader: { first_name: "Luke", last_name: "Skywalker" },
    created_at: "2019-10-23T12:37"
  };

  const updatedFile = {
    ...file,
    file_name: "image02.jpg",
    folder_name: "Damages",
    notes: "Test note..."
  };

  it("validates file name", async () => {
    // Given
    const { getByLabelText, getByText } = renderWithMockStore(
      <EditFileModalContainer file={file} />,
      {
        initialState
      }
    );

    const submitButtonEl = getByText("Update");

    // When
    fireEvent.change(getByLabelText("File name"), {
      target: { value: "" }
    });
    fireEvent.click(submitButtonEl);

    // Then
    await waitFor(() =>
      expect(getByText("File name is required")).toBeInTheDocument()
    );

    // When
    fireEvent.change(getByLabelText("File name"), {
      target: { value: "/invalid file name/" }
    });
    fireEvent.click(submitButtonEl);

    // Then
    await waitFor(() =>
      expect(getByText("Invalid file name")).toBeInTheDocument()
    );
  });

  it("updates a file", async () => {
    // Given
    const api = {
      updateFile: jest.fn().mockReturnValue(Promise.resolve(updatedFile))
    };

    const onClose = jest.fn();

    // When
    const {
      getByLabelText,
      getByText,
      store
    } = renderWithMockStore(
      <EditFileModalContainer file={file} onClose={onClose} />,
      { initialState, api }
    );

    const submitButtonEl = getByText("Update");

    fireEvent.change(getByLabelText("File name"), {
      target: { value: "image02" }
    });
    fireEvent.change(getByLabelText("Folder"), {
      target: { value: "Damages" }
    });
    fireEvent.change(getByLabelText("Notes"), {
      target: { value: "Test note..." }
    });

    fireEvent.click(submitButtonEl);
    expect(submitButtonEl).toBeDisabled();

    await waitFor(() => expect(submitButtonEl).toBeEnabled());

    // Then
    expect(api.updateFile).toHaveBeenCalledWith(
      { id: 1234, property_id: 123 },
      updatedFile
    );

    expect(store.getActions()[0]).toEqual(updateFileResponse(updatedFile));
  });
});
