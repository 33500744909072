import { Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { stringWithoutSpecialChars } from "../../../../common/forms/validationSchemas";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const validationSchema = yup.object().shape({
  listing_title: stringWithoutSpecialChars
    .required("Please provide a title")
    .max(50)
    .label("Listing title"),
  listing_summary: stringWithoutSpecialChars
    .required("Please provide a description")
    .max(500)
    .label("Listing description"),
  listing_space: stringWithoutSpecialChars,
  listing_access: stringWithoutSpecialChars,
  listing_interaction: stringWithoutSpecialChars,
  listing_neighborhood_overview: stringWithoutSpecialChars,
  listing_transit: stringWithoutSpecialChars,
  listing_nickname: yup.string().max(40).label("Listing nickname")
});

export const Description = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">Description</h4>

          <div
            className={
              !values.listing_title ? "field-wrapper invalid" : "field-wrapper"
            }
          >
            <FormGroup
              name="listing_title"
              label="Listing title"
              component="textarea"
              airbnbSync
              description="Highlight what makes your place special."
              characterCount={50}
            />
          </div>

          <div
            className={
              !values.listing_summary
                ? "field-wrapper invalid"
                : "field-wrapper"
            }
          >
            <FormGroup
              name="listing_summary"
              label="Listing description"
              component="textarea"
              rows={5}
              airbnbSync
              characterCount={500}
              description="Give guests a sense of what it’s like to stay at your place, including why they’ll love staying there."
            />
          </div>

          <FormGroup
            name="listing_nickname"
            label="Listing nickname"
            component="textarea"
            characterCount={40}
            airbnbSync
          />

          <FormGroup
            name="listing_space"
            label="The space"
            component="textarea"
            rows={5}
            airbnbSync
            description="Provide a general description of what the property and rooms are like so guests know what to expect."
          />

          <FormGroup
            name="listing_access"
            label="Guest access"
            component="textarea"
            rows={5}
            airbnbSync
            description="Let guests know which parts of the space they’ll be able to access."
          />

          <FormGroup
            name="listing_interaction"
            label="Interaction with guests"
            component="textarea"
            rows={5}
            airbnbSync
            description="Tell guests if you’ll be available to offer help throughout their stay and how you'll keep in touch with them."
          />

          <FormGroup
            name="listing_neighborhood_overview"
            label="Neighbourhood description"
            component="textarea"
            rows={5}
            airbnbSync
          />

          <FormGroup
            name="listing_transit"
            label="Getting around"
            component="textarea"
            rows={5}
            airbnbSync
          />

          <FormGroup
            name="listing_notes"
            label="Other details to note"
            component="textarea"
            rows={5}
            airbnbSync
            description="Include any special info you want potential guests to know before booking that isn't covered in other settings."
          />
        </HomeInfoForm>
      )}
    </Formik>
  );
};
