import get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";

import { isUnprocessableEntityError } from "../../../common/errors";
import { useAsync } from "../../../common/hooks/useAsync";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import * as api from "./api";
import { getChangedValues, prepareAmenityValuesForBackend } from "./helpers";

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div>
      <i className="fa fa-spinner fa-spin" />
    </div>
    <h5>Loading the onboarding...</h5>
  </div>
);

export const HomeInfoContext = React.createContext({
  property: null,
  homeInfo: null,
  airbnbListingData: null,
  externalKeysCount: 0,
  isModified: false,
  sidebarDraft: {}
});

export const HomeInfoProvider = ({ property, externalKeysCount, children }) => {
  const { loading, value: homeInfoValue } = useAsync(
    api.fetchHomeInfo,
    property.id
  );
  const [listingDataLoading, setListingDataLoading] = useState(false);
  const [homeInfo, setHomeInfo] = useState({});
  const [airbnbListingData, setAirbnbListingData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [sidebarDraft, setSidebarDraft] = useState({});

  useEffect(() => {
    setHomeInfo(homeInfoValue);
  }, [homeInfoValue]);

  const fetchAirbnbListingData = useCallback(async () => {
    setListingDataLoading(true);

    try {
      const airbnbListingDataResp = await api.fetchAirbnbListingData(
        property.airbnb_id
      );
      setAirbnbListingData(airbnbListingDataResp);
    } finally {
      setListingDataLoading(false);
    }
  }, [property]);

  useEffect(() => {
    if (property.airbnb_id) fetchAirbnbListingData();
  }, [fetchAirbnbListingData, property.airbnb_id]);

  const updateInternalNotes = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const { __saveAndContinueHandler, ...data } = values;
        const updatedHomeInfo = await api.updateHomeInfoInternalNotes(
          property.id,
          data
        );

        setHomeInfo(updatedHomeInfo);
        showSuccessToast("Internal notes were successfully updated");
      } catch (error) {
        showErrorToast("Unable to save internal notes");
      } finally {
        setSubmitting(false);
      }
    },
    [property.id]
  );

  const tameValuesBeforeSending = useCallback(
    (values, modifiedValues) => {
      const tamed = values.amenityOptions
        ? prepareAmenityValuesForBackend(values.amenityOptions)
        : modifiedValues;

      // Don't send notes for unchecked tasks, bring back the initial values
      [
        "gather_towels",
        "throw_trash",
        "lock_up",
        "return_keys",
        "additional_requests"
      ].forEach((key) => {
        if (
          modifiedValues?.home_info_checkout_tasks_attributes &&
          modifiedValues.home_info_checkout_tasks_attributes[key] === false
        ) {
          const notesKey = `${key}_notes`;
          // Bring back the unmodified notes if the task is not checked
          tamed.home_info_checkout_tasks_attributes[notesKey] =
            homeInfo.home_info_checkout_tasks_attributes[notesKey];
        }
      });

      return tamed;
    },
    [homeInfo]
  );

  const updateHomeInfo = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const { __saveAndContinueHandler, ...data } = values;

        const modifiedValues = getChangedValues(data, homeInfo);

        if (!Object.keys(modifiedValues).length) {
          if (__saveAndContinueHandler) {
            __saveAndContinueHandler();
          } else {
            showSuccessToast("No changes were made");
          }

          return;
        }

        const updatedHomeInfo = await api.updateHomeInfo(
          property.id,
          tameValuesBeforeSending(data, modifiedValues)
        );
        setHomeInfo(updatedHomeInfo);

        showSuccessToast("Home info was successfully updated");

        setIsModified(false);
        setSidebarDraft({});

        // A workaround - without `setTimeout` the prompt will be displayed
        // before formik's `dirty` flag  is updated.
        if (__saveAndContinueHandler) {
          setTimeout(() => __saveAndContinueHandler());
        }
      } catch (error) {
        if (isUnprocessableEntityError(error)) {
          const errors = get(error, "response.data.errors");
          showErrorToast(`The form contains some errors:\n${errors}`);
        } else {
          showErrorToast("Unable to save the form");
        }
      } finally {
        setSubmitting(false);
      }
    },
    [homeInfo, property.id, tameValuesBeforeSending]
  );

  if (loading || listingDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <HomeInfoContext.Provider
      value={{
        property,
        externalKeysCount,
        homeInfo,
        airbnbListingData,
        updateHomeInfo,
        updateInternalNotes,
        isModified,
        setIsModified,
        sidebarDraft,
        setSidebarDraft
      }}
    >
      {children}
    </HomeInfoContext.Provider>
  );
};
