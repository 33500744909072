import orderBy from "lodash/orderBy";

export const selectLoadingFiles = (state) => state.files.loading;

const pickFiles = (state, folder = null) => {
  const { selection, list } = state.files;

  // add selected flag to each file
  const files = list.reduce((acc, file) => {
    // Seems this is working fine but checkboxes are not checked after pressing select all
    acc.push({
      ...file,
      selected: selection.ids.includes(file.id)
    });

    return acc;
  }, []);

  // filter files by folder if given
  if (folder) {
    return files.filter(({ folder_name }) => folder_name === folder);
  }

  return files;
};

export const anyFilesSelected = (state) => {
  const { selection } = state.files;
  return selection.ids.length > 0;
};

export const allFilesSelected = (state) => {
  const { selection } = state.files;

  const currentFolderFiles = pickFiles(state, state.files.currentFolderName);
  return selection.all || selection.ids.length === currentFolderFiles.length;
};

export const selectedFilesIds = (state) => state.files.selection.ids;

export const countFilesByFolder = (state) =>
  pickFiles(state)
    .map((file) => file.folder_name)
    .reduce(
      (counters, folderName) => ({
        ...counters,
        [folderName]: counters[folderName] ? counters[folderName] + 1 : 1
      }),
      {}
    );

export const selectCurrentFolderName = (state) => state.files.currentFolderName;

export const selectFilesSorting = (state) => state.files.sorting;

export const selectSortedFiles = (state) => {
  const folderName = selectCurrentFolderName(state);
  if (!folderName) return [];

  const files = pickFiles(state, folderName);

  const { field, direction } = selectFilesSorting(state);
  return orderBy(files, field, direction);
};
