import "./Toggle.scss";

import classNames from "classnames";
import React from "react";

import FieldIcon from "../../components/properties/HomeInfo/components/FieldIcon";

export const Toggle = ({
  id,
  label,
  checked,
  onChange,
  description,
  containerClass = "",
  disabled = false,
  changeOrder = false,
  name
}) => (
  <div className={`toggle__container ${containerClass}`}>
    <input
      id={id}
      type="checkbox"
      className="toggle__input"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      name={name}
    />

    {changeOrder && (
      <label htmlFor={id} className="toggle__text-label">
        {label}
        {description && (
          <FieldIcon
            icon={<i className="fa fa-info-circle" />}
            tooltipText={description}
          />
        )}
      </label>
    )}

    <label htmlFor={id} className={classNames("toggle", { disabled })}>
      <span className="toggle__track" />
    </label>

    {!changeOrder && (
      <label htmlFor={id} className="toggle__text-label">
        {label}
      </label>
    )}
  </div>
);
