import { Formik, Field } from "formik";
import React, { useContext, useState } from "react";
import * as yup from "yup";

import { Toggle } from "../../../../common/forms/Toggle";
import { stringWithoutSpecialChars } from "../../../../common/forms/validationSchemas";
import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { SIDEBAR_NAMES } from "../sections";
import {
  PROPERTY_GROUP_TYPES,
  APARTMENTS,
  BNB,
  BOUTIQUE_HOTELS_AND_MORE,
  HOUSES,
  SECONDARY_UNITS,
  UNIQUE_HOMES,
  ROOM_TYPE_CATEGORY,
  PET_CAPACITY_OPTIONS,
  BOOLEAN_OPTIONS,
  REVERSE_BOOLEAN_OPTIONS
} from "./constants/ListingPreferencesConstants";

const PROPERTY_TYPES = {
  apartments: APARTMENTS,
  bnb: BNB,
  boutique_hotels_and_more: BOUTIQUE_HOTELS_AND_MORE,
  houses: HOUSES,
  secondary_units: SECONDARY_UNITS,
  unique_homes: UNIQUE_HOMES
};

const SHORT_TERM_FIRM = "better_strict_with_grace_period";
const SHORT_TERM_STRICT = "strict_14_with_grace_period";

const LONG_TERM_FIRM = "CANCEL_LONG_TERM_FAIR";
const LONG_TERM_STRICT = "CANCEL_LONG_TERM_WITH_GRACE_PERIOD";

export const validationSchema = yup.object().shape({
  children_not_allowed_details: yup
    .string()
    .max(300)
    .label("Children not allowed details"),

  home_info_instant_book_setting_attributes: yup.object().shape({
    pre_booking_message_text: stringWithoutSpecialChars
      .max(500)
      .label("Pre-booking message text")
  })
});

export const ListingPreferences = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  const [selectedPropertyTypeGroup, setSelectedPropertyTypeGroup] = useState(
    homeInfo.property_type_group
  );

  const handlePropertyTypeGroupChange = (e) => {
    const selectedGroup = e.target.value;
    setSelectedPropertyTypeGroup(selectedGroup);
  };

  const onSubmit = (values, form) => {
    const newValues = { ...values };

    /*
      If firm_or_non_refundable set it to better_strict_with_grace_period but with non_refundable_price_factor present
      If strict_or_non_refundable set it to strict_14_with_grace_period but with non_refundable_price_factor present
    */
    if (
      values.home_info_cancellation_policy_attributes.short_term ===
      "firm_or_non_refundable"
    ) {
      newValues.home_info_cancellation_policy_attributes.short_term = SHORT_TERM_FIRM;
    }

    if (
      values.home_info_cancellation_policy_attributes.short_term ===
      "strict_or_non_refundable"
    ) {
      newValues.home_info_cancellation_policy_attributes.short_term = SHORT_TERM_STRICT;
    }

    if (
      values.home_info_cancellation_policy_attributes.short_term ===
        "firm_or_non_refundable" ||
      values.home_info_cancellation_policy_attributes.short_term ===
        "strict_or_non_refundable"
    ) {
      newValues.home_info_cancellation_policy_attributes.non_refundable_price_factor = 0.9;
    } else {
      newValues.home_info_cancellation_policy_attributes.non_refundable_price_factor = false;
    }

    updateHomeInfo(newValues, form);
  };

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, values }) => (
        <HomeInfoForm sidebarName={SIDEBAR_NAMES.PLATFORM_SETTINGS}>
          <h4 className="section-name">Property Categorization</h4>

          <FormGroup
            name="property_type_group"
            label="Which place is most like yours?"
            helpText="If blank, default apartments applies"
            component="select"
            visibleToGuests
            value={selectedPropertyTypeGroup}
            onChange={(e) => {
              handlePropertyTypeGroupChange(e);
              handleChange(e); // Manually update Formik state
            }}
          >
            <SelectOptions options={PROPERTY_GROUP_TYPES} />
          </FormGroup>

          <FormGroup
            name="property_type_category"
            label="Property type"
            helpText="If blank, default apartment applies"
            component="select"
            visibleToGuests
          >
            <SelectOptions
              options={
                PROPERTY_TYPES[selectedPropertyTypeGroup] || [
                  homeInfo.property_type_category
                ]
              }
            />
          </FormGroup>

          <FormGroup
            name="room_type_category"
            label="Listing type"
            helpText="If blank, default entire_room applies"
            component="select"
            visibleToGuests
          >
            <SelectOptions options={ROOM_TYPE_CATEGORY} />
          </FormGroup>

          <h4 className="section-name">Guest controls</h4>
          <FormGroup
            name="unsuitable_for_children"
            label="Is the property suitable for children 2-12?"
            component="select"
            visibleToGuests
            airbnbSync
          >
            <SelectOptions
              includeBlank={false}
              options={REVERSE_BOOLEAN_OPTIONS}
            />
          </FormGroup>

          <FormGroup
            name="children_not_allowed_details"
            label="Children not allowed details"
            component="textarea"
            visibleToGuests
            rows={5}
            airbnbSync
            characterCount={300}
          />

          <FormGroup
            name="unsuitable_for_infants"
            label="Is the property suitable for children under 2?"
            component="select"
            visibleToGuests
            airbnbSync
          >
            <SelectOptions
              includeBlank={false}
              options={REVERSE_BOOLEAN_OPTIONS}
            />
          </FormGroup>

          <FormGroup
            name="allows_pets_as_host"
            label="Does the host allow pets?"
            component="select"
            visibleToGuests
            airbnbSync
          >
            <SelectOptions includeBlank={false} options={BOOLEAN_OPTIONS} />
          </FormGroup>

          <FormGroup
            name="pet_capacity"
            label="Pets allowed"
            component="select"
            visibleToGuests
            airbnbSync
          >
            <SelectOptions
              includeBlank={false}
              options={PET_CAPACITY_OPTIONS}
            />
          </FormGroup>

          <FormGroup
            name="allows_smoking_as_host"
            label="Allows smoking as host"
            component="select"
            visibleToGuests
            airbnbSync
          >
            <SelectOptions includeBlank={false} options={BOOLEAN_OPTIONS} />
          </FormGroup>

          <FormGroup
            name="allows_events_as_host"
            label="Events allowed"
            component="select"
            visibleToGuests
            airbnbSync
          >
            <SelectOptions includeBlank={false} options={BOOLEAN_OPTIONS} />
          </FormGroup>

          <h4 className="section-name">Policies</h4>
          <div className="form-row border p-3">
            <div className="col-md-4">
              <h5>Short term policy</h5>
              <div className="form-check">
                <Field
                  type="radio"
                  className="form-check-input"
                  id="short_term_firm"
                  name="home_info_cancellation_policy_attributes.short_term"
                  value={SHORT_TERM_FIRM}
                />
                <div className="policy">
                  <label className="form-check-label" htmlFor="short_term_firm">
                    Firm
                    <p className="desc">
                      Full refund for cancellations up to 30 days before
                      check-in. If booked fewer than 30 days before check-in,
                      full refund for cancellations made within 48 hours of
                      booking and at least 14 days before check-in. After that,
                      50% refund up to 7 days before check-in. No refund after
                      that.
                    </p>
                  </label>
                </div>
                <Field
                  type="radio"
                  className="form-check-input"
                  id="short_term_firm_non_refundable"
                  name="home_info_cancellation_policy_attributes.short_term"
                  value="firm_or_non_refundable"
                  disabled
                />
                <div className="policy">
                  <label
                    className="form-check-label"
                    htmlFor="short_term_firm_non_refundable"
                  >
                    Firm or Non-refundable
                    <p className="desc">
                      In addition to Firm, offer a non-refundable option -
                      guests pay 10% less, but you keep your payout no matter
                      when they cancel.
                    </p>
                  </label>
                </div>
                <Field
                  type="radio"
                  className="form-check-input"
                  id="short_term_strict"
                  name="home_info_cancellation_policy_attributes.short_term"
                  value={SHORT_TERM_STRICT}
                  disabled
                />
                <div className="policy">
                  <label
                    className="form-check-label"
                    htmlFor="short_term_strict"
                  >
                    Strict
                    <p className="desc">
                      Full refund for cancellations made within 48 hours of
                      booking, if the check-in date is at least 14 days away.
                      50% refund for cancellations made at least 7 days before
                      check-in. No refunds for cancellations made within 7 days
                      of check-in.
                    </p>
                  </label>
                </div>
                <Field
                  type="radio"
                  className="form-check-input"
                  id="short_term_strict_non_refundable"
                  name="home_info_cancellation_policy_attributes.short_term"
                  value="strict_or_non_refundable"
                  disabled
                />
                <div className="policy">
                  <label
                    className="form-check-label"
                    htmlFor="short_term_strict"
                  >
                    Strict or Non-refundable
                    <p className="desc">
                      In addition to Strict, offer a non-refundable option -
                      guests pay 10% less, but you keep your payout no matter
                      when they cancel.
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row border p-3 mt-3">
            <div className="col-md-4">
              <h5>Long term policy</h5>
              <div className="form-check">
                <Field
                  type="radio"
                  className="form-check-input"
                  id="long_term_firm"
                  name="home_info_cancellation_policy_attributes.long_term"
                  value={LONG_TERM_FIRM}
                  disabled
                />
                <div className="policy">
                  <label className="form-check-label" htmlFor="long_term_firm">
                    Firm
                    <p className="desc">
                      Full refund up to 30 days before check-in. After that, the
                      first 30 days of the stay are non-refundable.
                    </p>
                  </label>
                </div>
                <Field
                  type="radio"
                  className="form-check-input"
                  id="long_term_strict"
                  name="home_info_cancellation_policy_attributes.long_term"
                  value={LONG_TERM_STRICT}
                />
                <div className="policy">
                  <label
                    className="form-check-label"
                    htmlFor="long_term_strict"
                  >
                    Strict
                    <p className="desc">
                      Full refund if cancelled within 48 hours of booking and at
                      least 28 days before check-in. After that, the first 30
                      days of the stay are non-refundable.
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row border p-3 mt-3">
            <div className="instant-book-wrapper">
              <h5>Instant book optional settings</h5>
              <div className="instant-book">
                <Toggle
                  id="good_track_record"
                  label="Good track record"
                  name="home_info_instant_book_setting_attributes.good_track_record"
                  disabled
                  checked={
                    values.home_info_instant_book_setting_attributes
                      .good_track_record
                  }
                  onChange={handleChange}
                  changeOrder
                  description="Only allow guests who have stayed on Airbnb without incidents or negative reviews."
                />
              </div>
              <div className="instant-book">
                <Toggle
                  id="pre_booking_message"
                  name="home_info_instant_book_setting_attributes.pre_booking_message"
                  label="Pre-booking message"
                  checked={
                    values.home_info_instant_book_setting_attributes
                      .pre_booking_message
                  }
                  onChange={handleChange}
                  changeOrder
                  description="Require guests to read and respond to a message before they confirm their reservation."
                />
              </div>
              <FormGroup
                name="home_info_instant_book_setting_attributes.pre_booking_message_text"
                label="Pre-booking message text"
                component="textarea"
                rows={5}
                disabled={
                  !values.home_info_instant_book_setting_attributes
                    .pre_booking_message
                }
              />
            </div>
          </div>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
