import {
  prepareAmenityValuesForBackend,
  prepareAmenitiesData,
  hasAirbnbRequiredFields
} from "./helpers";

const today = new Date(2021, 10, 28);

test("prepareAmenityValuesForBackend", () => {
  const values = [
    {
      home_info_id: 45,
      home_info_amenity_type_id: 13,
      external_notes: "Don't scare the kitten",
      internal_notes: "Miau miau miau",
      available: true,
      amenity_id: 456
    },
    {
      home_info_amenity_type_id: 37,
      external_notes: "",
      internal_notes: "What does the fox say",
      available: true,
      id: 34
    },
    {
      home_info_id: 45,
      home_info_amenity_type_id: 37,
      external_notes: "",
      internal_notes: "What's the meaning of Stonehenge",
      available: false,
      deleted_at: null,
      amenity_id: 78
    },
    {
      home_info_id: 45,
      home_info_amenity_type_id: 37,
      external_notes: "",
      internal_notes: "This amenity shouldn't be visible",
      available: false,
      deleted_at: new Date(2020, 11, 10)
    }
  ];

  const expected = {
    home_info_amenities_attributes: [
      {
        external_notes: "Don't scare the kitten",
        internal_notes: "Miau miau miau",
        id: 456,
        deleted_at: null
      },
      {
        external_notes: "",
        internal_notes: "What does the fox say",
        home_info_amenity_type_id: 34
      },
      {
        id: 78,
        external_notes: "",
        internal_notes: "What's the meaning of Stonehenge",
        deleted_at: new Date(2021, 10, 28)
      }
    ]
  };
  jest.spyOn(Date, "now").mockImplementation(() => today);
  expect(prepareAmenityValuesForBackend(values)).toEqual(expected);
});

test("prepareAmenitiesData", () => {
  const types = [
    {
      id: 1,
      name: "Barbecue",
      key: "barbecue"
    },
    {
      id: 2,
      name: "Blender",
      key: "blender"
    },
    {
      id: 3,
      name: "Kettle",
      key: "kettle"
    }
  ];

  const amenities = [
    {
      created_at: "2023-07-20T08:56:35.341Z",
      deleted_at: null,
      external_notes: "very cool blender",
      home_info_amenity_type_id: 2,
      home_info_id: 2,
      id: 31,
      internal_notes: "don't use it",
      updated_at: "2023-07-20T10:26:59.250Z"
    },
    {
      created_at: "2023-07-20T08:56:35.341Z",
      deleted_at: "2023-07-27T10:26:59.250Z",
      external_notes: "Nice barbecue, can make sausage",
      home_info_amenity_type_id: 1,
      home_info_id: 2,
      id: 32,
      internal_notes: "hehe",
      updated_at: "2023-07-20T10:26:59.250Z"
    }
  ];

  const expected = [
    {
      name: "Barbecue",
      key: "barbecue",
      created_at: "2023-07-20T08:56:35.341Z",
      deleted_at: "2023-07-27T10:26:59.250Z",
      external_notes: "Nice barbecue, can make sausage",
      home_info_amenity_type_id: 1,
      home_info_id: 2,
      id: 32,
      internal_notes: "hehe",
      updated_at: "2023-07-20T10:26:59.250Z",
      amenity_id: 32,
      available: false,
      expanded: false
    },
    {
      name: "Blender",
      key: "blender",
      created_at: "2023-07-20T08:56:35.341Z",
      deleted_at: null,
      external_notes: "very cool blender",
      home_info_amenity_type_id: 2,
      home_info_id: 2,
      id: 31,
      internal_notes: "don't use it",
      updated_at: "2023-07-20T10:26:59.250Z",
      amenity_id: 31,
      available: true,
      expanded: false
    },
    {
      id: 3,
      name: "Kettle",
      key: "kettle",
      available: false,
      expanded: false
    }
  ];

  expect(prepareAmenitiesData(types, amenities)).toEqual(expected);
});

describe("hasAirbnbRequiredFields", () => {
  describe("simple array as required fields", () => {
    describe("no listing photos field", () => {
      const requiredFields = [
        "field1",
        "field2",
        "field3",
        "nested.field4",
        "nested.like_really_nested.field5"
      ];

      it("returns true if no fields are null or undefined or empty strings", () => {
        const fields = {
          field1: "test test",
          field2: true,
          field3: false,
          nested: {
            field4: "test",
            like_really_nested: {
              field5: true
            }
          },
          property_attributes: {
            listing_photos_ready: false
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(true);
      });

      it("returns false if some fields are undefined", () => {
        const fields = {
          field1: "test test",
          nested: {
            field4: "test",
            like_really_nested: {
              field5: true
            }
          },
          property_attributes: {
            listing_photos_ready: false
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });

      it("returns false if some fields are null", () => {
        const fields = {
          field1: "test test",
          field2: null,
          field3: null,
          nested: {
            field4: null,
            like_really_nested: {
              field5: true
            }
          },
          property_attributes: {
            listing_photos_ready: false
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });

      it("returns false if some fields are empty strings", () => {
        const fields = {
          field1: "test test",
          field2: "",
          field3: true,
          nested: {
            field4: null,
            like_really_nested: {
              field5: true
            }
          },
          property_attributes: {
            listing_photos_ready: false
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });
    });
    describe("listing photos field", () => {
      const requiredFields = ["listing_photos", "field1", "nested.field2"];

      it("returns false if there are no photos and listingPhotoReady is true", () => {
        const fields = {
          field1: true,
          nested: {
            field2: true
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });

      it("returns true if there are no photos and listingPhotoReady is false", () => {
        const fields = {
          field1: true,
          nested: {
            field2: true
          },
          property_attributes: {
            listing_photos_ready: false
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(true);
      });

      describe("has photos", () => {
        it("returns true if no fields are null or undefined or empty strings", () => {
          const fields = {
            field1: true,
            nested: {
              field2: true
            },
            property_attributes: {
              listing_photos_ready: true
            }
          };
          const result = hasAirbnbRequiredFields(5, fields, requiredFields);
          expect(result).toBe(true);
        });

        it("returns false if some fields are null", () => {
          const fields = {
            field1: true,
            nested: {
              field2: null
            },
            property_attributes: {
              listing_photos_ready: true
            }
          };
          const result = hasAirbnbRequiredFields(5, fields, requiredFields);
          expect(result).toBe(false);
        });

        it("returns false if some fields are empty strings", () => {
          const fields = {
            field1: true,
            nested: {
              field2: ""
            },
            property_attributes: {
              listing_photos_ready: true
            }
          };
          const result = hasAirbnbRequiredFields(5, fields, requiredFields);
          expect(result).toBe(false);
        });

        it("returns false if some fields are undefined", () => {
          const fields = {
            nested: {
              field2: false
            },
            property_attributes: {
              listing_photos_ready: true
            }
          };
          const result = hasAirbnbRequiredFields(5, fields, requiredFields);
          expect(result).toBe(false);
        });
      });
    });
  });

  describe("object as required fields", () => {
    describe("no listing photos field", () => {
      const requiredFields = {
        TEST: ["field1", "field2", "nested.field3", "really.nested.field4"],
        ABC: ["fieldfield"],
        XD: ["field5", "nested.field6"]
      };

      it("returns true if no fields are null or undefined or empty strings", () => {
        const fields = {
          field1: "test test",
          field2: true,
          field3: false,
          field5: true,
          fieldfield: "abcd",
          nested: {
            field3: "test",
            field6: 666
          },
          really: {
            nested: {
              field4: false
            }
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, false);

        expect(result).toBe(true);
      });

      it("returns false if some fields are undefined", () => {
        const fields = {
          field1: "test test",
          field2: true,
          fieldfield: "abcd",
          nested: {
            field3: "test",
            field6: 666
          },
          really: {
            nested: {
              field4: false
            }
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });

      it("returns false if some fields are null", () => {
        const fields = {
          field1: "test test",
          field2: true,
          field3: false,
          field5: true,
          fieldfield: "abcd",
          nested: {
            field3: null,
            field6: 666
          },
          really: {
            nested: {
              field4: false
            }
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });

      it("returns false if some fields are empty strings", () => {
        const fields = {
          field1: "test test",
          field2: true,
          field3: false,
          field5: true,
          fieldfield: "abcd",
          nested: {
            field3: "",
            field6: 666
          },
          really: {
            nested: {
              field4: false
            }
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };

        const result = hasAirbnbRequiredFields(0, fields, requiredFields);

        expect(result).toBe(false);
      });
    });
  });
  describe("listing photos field", () => {
    const requiredFields = {
      TEST: ["field1"],
      TEST2: ["nested.field2"],
      ABC: ["listing_photos", "nested.field3", "nested.field4"]
    };

    it("returns false if there are no photos and listingPhotoReady is true", () => {
      const fields = {
        field1: true,
        nested: {
          field2: false,
          field3: "test",
          field4: true
        },
        property_attributes: {
          listing_photos_ready: true
        }
      };

      const result = hasAirbnbRequiredFields(0, fields, requiredFields);

      expect(result).toBe(false);
    });

    it("returns true if there are no photos and listingPhotoReady is false", () => {
      const fields = {
        field1: true,
        nested: {
          field2: false,
          field3: "test",
          field4: true
        },
        property_attributes: {
          listing_photos_ready: false
        }
      };

      const result = hasAirbnbRequiredFields(0, fields, requiredFields);

      expect(result).toBe(true);
    });

    describe("has photos", () => {
      it("returns true if no fields are null or undefined or empty strings", () => {
        const fields = {
          field1: true,
          nested: {
            field2: false,
            field3: "test",
            field4: true
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };
        const result = hasAirbnbRequiredFields(5, fields, requiredFields);
        expect(result).toBe(true);
      });

      it("returns false if some fields are null", () => {
        const fields = {
          field1: true,
          nested: {
            field2: null,
            field3: "test",
            field4: true
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };
        const result = hasAirbnbRequiredFields(5, fields, requiredFields);
        expect(result).toBe(false);
      });

      it("returns false if some fields are undefined", () => {
        const fields = {
          field1: true,
          nested: {
            field3: "test",
            field4: true
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };
        const result = hasAirbnbRequiredFields(5, fields, requiredFields);
        expect(result).toBe(false);
      });

      it("returns false if some fields are empty strings", () => {
        const fields = {
          field1: true,
          nested: {
            field2: false,
            field3: "",
            field4: true
          },
          property_attributes: {
            listing_photos_ready: true
          }
        };
        const result = hasAirbnbRequiredFields(5, fields, requiredFields);
        expect(result).toBe(false);
      });
    });
  });
});
