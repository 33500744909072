import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SwalActions, modal } from "../../../../common/modals";
import { selectedFilesIds as selectedFilesIdsSelector } from "../data/files/selectors";
import { deleteFiles } from "../data/files/thunks";

export const BulkDeleteButton = () => {
  const dispatch = useDispatch();
  const selectedFilesIds = useSelector(selectedFilesIdsSelector);

  const confirmationPopUp = () =>
    new Promise((res) => {
      const closeConfirmation = () => modal.close();
      const onConfirm = () => {
        res({ confirmed: true });
        closeConfirmation();
      };

      modal.fire({
        html: (
          <>
            <p>Are you sure you want to delete selected files?</p>
            <SwalActions
              confirmText="Yes, delete forever"
              cancelText="No"
              onCancel={closeConfirmation}
              onConfirm={onConfirm}
            />
          </>
        ),
        showConfirmButton: false,
        showCancelButton: false,
        icon: "error",
        iconHtml: '<i class="fas fa-exclamation"></i>',
        animation: false
      });
    });

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const { confirmed } = await confirmationPopUp();

      if (!confirmed) {
        return;
      }

      await dispatch(deleteFiles(selectedFilesIds));
    },
    [dispatch, selectedFilesIds]
  );

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={handleSubmit}>
        <i className="fa fa-trash" /> Delete
      </button>
    </>
  );
};
